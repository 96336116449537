/* CUSTOM CLASSES */
.glowCookies__border {
  border: 1px solid #e6e6e6 !important;
}

.glowCookies__left {
  left: 15px;
}

.glowCookies__right {
  right: 15px;
}

.glowCookies__show {
  opacity: 1 !important;
  visibility: visible !important;
  transform: scale(1) !important;
}

/* COMMON STYLES */
/* ========================= */
.glowCookies__banner {
  opacity: 0;
  visibility: hidden;
  transform: scale(0.9);
  font-family: inherit;
  position: fixed;
  width: auto;
  max-width: 375px;
  z-index: 999;
  -webkit-box-shadow: 0 .625em 1.875em rgba(2,2,3,.2);
  -moz-box-shadow: 0 .625em 1.875em rgba(2,2,3,.2);
  box-shadow: 0 .625em 1.875em rgba(2,2,3,.2);
  transition: transform .2s ease, opacity .2s ease !important;
}

.glowCookies__banner .accept__btn__styles {
  border: none;
  padding: 13px 15px;
  font-size: 15px;
  font-family: inherit;
  width: 49%;
  cursor: pointer;
  font-weight: bolder;
  transition: filter 0.15s;
  user-select: none;
}

.glowCookies__banner .settings__btn__styles {
  border: none;
  padding: 13px 15px;
  font-family: inherit;
  font-size: 15px;
  width: 49%;
  cursor: pointer;
  font-weight: bolder;
  transition: filter 0.15s;
  user-select: none;
}

.glowCookies__banner .btn__section button:focus {
  outline: none;
  transform: scale(0.95);
  -webkit-filter: contrast(75%);
  filter: contrast(75%);
}

.glowCookies__banner .btn__section button:hover {
  -webkit-filter: contrast(85%);
  filter: contrast(85%);
}

@media (max-width: 455px) {
  .btn__section .btn__accept {
    width: 100%;
    margin-bottom: 7px;
  }

  .btn__section .btn__settings {
      width: 100%;
  }
}

/* BANNER STYLE 1 */
/* ========================= */
.glowCookies__banner__1 {
  bottom: 15px;
  border-radius: 20px;
  padding: 20px 25px;
}

.glowCookies__banner__1 > h3 {
  font-size: 24px;
  margin: 0px;
  padding: 8px 0;
}

.glowCookies__banner__1 > p {
  font-size: 15px;
  margin: 0px;
  padding: 0px;
  line-height: 1.3;
}

.glowCookies__banner__1 .read__more {
  font-weight: bolder;
}

.glowCookies__banner__1 .read__more:hover {
  color: #666666;
}

.glowCookies__banner__1 .btn__section {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 20px;
}

.glowCookies__banner__1 .btn__section button {
  border-radius: 10px;
}

@media (max-width: 455px) {
  .glowCookies__banner__1 {
      bottom: 0px;
      left: 0px;
      right: 0px;
      margin: 7px;
  }

  .glowCookies__banner__1 .btn__section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 20px;
  }
}


/* BANNER STYLE 2 */
/* ========================= */
.glowCookies__banner__2 {
  bottom: 15px;
  border-radius: 5px;
  padding: 25px 35px;
}

.glowCookies__banner__2 > h3 {
  font-size: 24px;
  margin: 0px;
  padding: 8px 0;
}

.glowCookies__banner__2 > p {
  font-size: 15px;
  margin: 0px;
  padding: 0px;
  line-height: 1.3;
}

.glowCookies__banner__2 .read__more {
  font-weight: bolder;
}

.glowCookies__banner__2 .read__more:hover {
  opacity: 0.8;
}

.glowCookies__banner__2 .btn__section {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 20px;
}

.glowCookies__banner__2 .btn__section button {
  border-radius: 5px;
}

@media (max-width: 455px) {
  .glowCookies__banner__2 {
      bottom: 0px;
      left: 0px;
      right: 0px;
      border-radius: 0px;
      border: 0px;
      max-width: 550px;
  }

  .glowCookies__banner__2 .btn__section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 20px;
  }
}

/* BANNER STYLE 3 */
/* ========================= */
.glowCookies__banner__3 {
  bottom: 15px;
  border-radius: 0px;
  padding: 25px 35px;
}

.glowCookies__banner__3 > h3 {
  font-size: 30px;
  margin: 0px;
  padding: 8px 0;
}

.glowCookies__banner__3 > p {
  font-size: 15px;
  margin: 0px;
  padding: 0px;
  line-height: 1.3;
}

.glowCookies__banner__3 .read__more {
  font-weight: bolder;
}

.glowCookies__banner__3 .read__more:hover {
  opacity: 0.8;
}

.glowCookies__banner__3 .btn__section {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 25px;
}

.glowCookies__banner__3 .btn__section button {
  border-radius: 0px;
}

@media (max-width: 455px) {
  .glowCookies__banner__3 {
      bottom: 0px;
      left: 0px;
      right: 0px;
      border-radius: 0px;
      border: 0px;
      max-width: 550px;
  }

  .glowCookies__banner__3 .btn__section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 20px;
  }
}

/* Prebanner styles */
/* =========================== */
.prebanner {
  position: fixed;
  bottom: 15px;
  z-index: 999;
  min-height: 50px;
  min-width: 125px;
  cursor: pointer;
  font-family: inherit;
  font-size: 15px;
  font-weight: bolder;
  line-height: normal;
  border: none;
  padding: 12px 18px;
  text-decoration: none;
  user-select: none;
  -webkit-box-shadow: 0 .625em 1.875em rgba(2,2,3,.1);
  -moz-box-shadow: 0 .625em 1.875em rgba(2,2,3,.1);
  box-shadow: 0 .625em 1.875em rgba(2,2,3,.1);
}

.prebanner__border__1 {
  border-radius: 15px;
}

.prebanner__border__2 {
  border-radius: 10px;
}

.prebanner__border__3 {
  border-radius: 2px;
}

.animation {
  transition: .2s;
}

.animation:hover {
  transform: scale(.97);
}

.prebanner:hover {
  text-decoration: none;
}

